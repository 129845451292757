<div id="header" class="align-items-center">
    <div class="container d-flex justify-content-between desktop-nav-toogle">    
        <div class="logo">
            <a routerLink="/"><img src="assets/logo.png" alt="" class="img-fluid"></a>
        </div>
        <nav id="navbar" class="navbar navbar-desktop">
            <ul>
              <li><a class="nav-link" routerLink="/">Home</a></li>
              <li><a class="nav-link" routerLink="/search-pass">Download</a></li>
              <li><a class="nav-link" *ngIf="!isLoggedIn" routerLink="/admin">Login</a></li>
              <li><a class="nav-link" *ngIf="isLoggedIn" routerLink="/logout">Logout</a></li>
              <li><a class="nav-link" routerLink="/contact">Contact Us</a></li>
            </ul>
          </nav><!-- .navbar -->
    </div>
    <div class="mobile-nav-toggle">
        <nav id="navbar" class="navbar navbar-mobile">
        <ul>
            <li><a class="nav-link" routerLink="/">Home</a></li>
            <li><a class="nav-link" routerLink="/search-pass">Download</a></li>
            <li><a class="nav-link" *ngIf="!isLoggedIn" routerLink="/admin">Login</a></li>
            <li><a class="nav-link" *ngIf="isLoggedIn" routerLink="/logout">Logout</a></li>
            <li><a class="nav-link" routerLink="/contact">Contact Us</a></li>
        </ul>
        </nav>
    </div>
</div>
<div class="clearfix"></div>