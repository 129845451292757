import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { fadeIn, fadeOut, scaleIn, scaleOut } from './carousel.animations';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition("void => *", [useAnimation(scaleIn,  {params: { time: '1300ms' }} )]),
      transition("* => void", [useAnimation(scaleOut,  {params: { time: '1300ms' }} )]),
    ])
  ]
})
export class CarouselComponent implements OnInit {
  @Input()
  slides;

  currentSlide = 0;

  constructor() { }

  ngOnInit(): void {    
    setInterval(() => { this.onNextClick()}, 5000);
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;  
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;    
  }

  showCurrentSlide(i: number) {
    this.currentSlide = i;
  }
}
