import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import domtoimage from 'dom-to-image';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() visitorData;
  thumbnail:any;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(this.visitorData.photo);
  }

  downloadPDF() {
    this.captureScreen();
  }

  captureScreen() {
    window.scrollTo(0,0);
    let data = window.document.getElementById('visitorPass');
    domtoimage.toJpeg(data, { bgcolor: '#fff' })
    .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'visitor-pass.jpeg';
        link.href = dataUrl;
        link.click();
    });
  }


  goToHome() {
    window.location.reload();
  }

}
