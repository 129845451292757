import { Component, OnInit, ViewChild } from '@angular/core';
import { VisitorsPassService } from '../services/visitors-pass.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent;
  tempVisitorsData: any[] = [];
  visitorsData: any[] = [];
  closeModal: string;
  loading: boolean = false;
  errorMessage: string = '';
  successMessage: boolean = false;
  isPhotoInvalid: boolean = false;
  photoErrorMsg: string = '';
  visitorImage: any = '';
  addModalLabel: string = 'Add Event';
  addModalButton: string = 'Create Event';

  columns = [
    { prop: 'eventname', name: 'Event Name' },
    { prop: 'event_date', name: 'Event Date' },
    { prop: 'contact_email', name: 'Contact Email' },
    { prop: 'contact_phone', name: 'Contact Phone' },
    { prop: 'event_location', name: 'Location' },
    { prop: 'event_email', name: 'Event Email' },
    { prop: 'event_phone', name: 'Event Contact' },    
  ]
  visitorData: any = {
    eventName: '',
    event_date: '',
    description: '',
    photo: '',
    contact_address: '',
    contact_phone: '',
    contact_email: '',
    event_location: '',
    event_address: '',
    event_phone: '',
    event_email: '',
    eventid: null
  }
  constructor(private visitorsPassService: VisitorsPassService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.visitorsPassService.getEvents().subscribe(data => {
      // cache our list
      this.tempVisitorsData = [...data];
      this.visitorsData = data;
    });
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  triggerModal(content, event_id) {
    if(event_id !== null) {
      this.addModalLabel = 'Update Event';
      this.addModalButton = 'Update Event';
      this.tempVisitorsData.forEach(event_details => {
        if(event_details.eventid === event_id) {
          this.visitorData = event_details;
          this.visitorData.eventName = event_details.eventname;
          this.visitorData.event_date = event_details.event_date.split('-')[1] + '/' + event_details.event_date.split('-')[2] + '/' + event_details.event_date.split('-')[0];
        }
      })
      // this.visitorData.eventName = res[0].eventname;
      // this.visitorData.event_date = new Date(res[0].event_date);
      console.log('Event Details: ', this.visitorData);
    } else {
      this.addModalLabel = 'Add Event';
      this.addModalButton = 'Create Event';
      this.visitorData = {
        eventName: '',
        event_date: '',
        description: '',
        photo: '',
        contact_address: '',
        contact_phone: '',
        contact_email: '',
        event_location: '',
        event_address: '',
        event_phone: '',
        event_email: '',
        eventid: null
      }
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      // this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempVisitorsData.filter(function (d) {
      return d.eventname.toLowerCase().indexOf(val) !== -1 ||
        d.event_email.toLowerCase().indexOf(val) !== -1 ||
        d.contact_email.toLowerCase().indexOf(val) !== -1 ||
        d.contact_phone.toLowerCase().indexOf(val) !== -1 ||
        d.event_phone.indexOf(val) !== -1 ||
        d.event_location.toLowerCase().indexOf(val) !== -1 ||
        !val;
    });

    // update the rows
    this.visitorsData = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  createEvent(visitorForm) {
    if (visitorForm.valid && !this.isPhotoInvalid) {
      this.loading = true;
      let d = new Date(this.visitorData.event_date);
      
      this.visitorData.event_date = d.getFullYear()+'-'+(d.getMonth()+1) +'-'+d.getDate();
      this.visitorsPassService.createEvent(this.visitorData).subscribe(data => {
        this.loading = false;
        if (data && data.contact_email) {
          this.successMessage = true;
        } else {
          if (data === "error") {
            this.errorMessage = 'Error Occurred while creating the event, please try again!';
          } else {
            this.errorMessage = data;
          }
          this.clearMessages();
        }
      }, error => {
        this.loading = false;
        this.errorMessage = 'Error Occurred while creating the event, please try again!';
        this.clearMessages();
      });
    }

  }

  clearMessages() {
    setTimeout(() => {
      this.errorMessage = '';
    }, 3000)
  }

  uploadImage(event) {
    if (event.target.value) {
      this.visitorData.photo = '';
      const file = event.target.files[0];
      const fileSize = Math.round((file.size / 1024) / 1000);
      if (fileSize > 1) {
        this.isPhotoInvalid = true;
        this.photoErrorMsg = "Image size must be below 1mb";
      } else if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
        this.isPhotoInvalid = false;
        this.changeFile(file).then((base64: string): any => {
          this.visitorData.photo = base64;
        });
      } else {
        this.isPhotoInvalid = true;
        this.photoErrorMsg = "Only JPEG's and Png file types are allowed";
      }
    }
  }

  selectListener(event) {
    console.log('Event Details: ', event);
  }
}
