<div class="container card-main-container">
    <div id="print-visitor-pass" class="card-container print-visitor-pass">
        <div class="row photo-bg-container">
            <div id="visitorPass" class="col-12 photo-bg">
                <img src="../../assets/expo-photo-background.png" width="87%" class="camera-img"/>
                <img  *ngIf="thumbnail" [src]="thumbnail" class="visitor-photo" /> 
                <div class="row visitor-details-container">
                    <div class="col-12 visitor-details">
                        <div class="visitor-name">{{visitorData.firstName}} {{visitorData.lastName}}</div>
                        <div class="visitor-title">{{visitorData.designation}}</div>
                        <div class="visitor-city">{{visitorData.city}}</div>
                        <div class="visitor-ticket-id">Ticket ID - F{{(visitorData.id ? "00000"+visitorData.id : '000000' ).slice(-6)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center mt-5">
        <button type="button" title="Back" (click)="goToHome()" class="btn btn-secondary back-btn">Back</button>
        <button type="button" title="Download" (click)="downloadPDF()" class="btn btn-primary back-btn">Download</button>
        <button ngxPrintItemButton divID="print-visitor-pass" class="btn btn-primary"> Print</button>
    </div>
</div>
