import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var window:any;
@Injectable({
  providedIn: 'root'
})

export class VisitorsPassService {
  urlPath: string = (window.location.host.indexOf('localhost') > -1)?'http://localhost':'.';
  constructor(private httpClient: HttpClient) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }  

  getVisitors():any {
    // return this.httpClient.get('./api/visitor.php', this.httpHeader);
    return this.httpClient.get(this.urlPath+'/api/visitor.php', this.httpHeader);
  }
  
  getEvents(showLatest:boolean = false):any {
    let appendURL = '';
    if(showLatest) {
      appendURL = '?latest';
    }
    // return this.httpClient.get('./api/event.php'+appendURL, this.httpHeader);
    return this.httpClient.get(this.urlPath+'/api/event.php'+appendURL, this.httpHeader);
  }

  getEventDetails(eventid) {
    // return this.httpClient.get('./api/event.php?eventid='+eventid, this.httpHeader);
    return this.httpClient.get(this.urlPath+'/api/event.php?eventid='+eventid, this.httpHeader);
  }

  getVisitor(visitorObj): any {
    // return this.httpClient.post('./api/visitor.php', JSON.stringify(visitorObj), this.httpHeader);
    return this.httpClient.post(this.urlPath+'/api/visitor.php', JSON.stringify(visitorObj), this.httpHeader);
  }
  
  createVisitor(visitor): any{
    // return this.httpClient.post('./api/visitor.php', JSON.stringify(visitor), this.httpHeader);
    return this.httpClient.post(this.urlPath+'/api/visitor.php', JSON.stringify(visitor), this.httpHeader);
  }

  createEvent(eventDetails): any{
    // return this.httpClient.post('./api/visitor.php', JSON.stringify(visitor), this.httpHeader);
    return this.httpClient.post(this.urlPath+'/api/event.php', JSON.stringify(eventDetails), this.httpHeader);
  }
}
