<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">
  <base href="/">
  <title>FotoTechPass</title>
  </head>
<body>
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</body>
</html>
