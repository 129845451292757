import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var window:any;
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  urlPath: string = (window.location.host.indexOf('localhost') > -1)?'http://localhost':'.';
  constructor(private httpClient: HttpClient) { }
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  login(loginObj): any {
    // return this.httpClient.post('./api/login.php', JSON.stringify(loginObj), this.httpHeader);
    return this.httpClient.post(this.urlPath+'/api/login.php', JSON.stringify(loginObj), this.httpHeader);
  }
}
