import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { SearchPassComponent } from './search-pass/search-pass.component';
import { ContactComponent } from './contact/contact.component';
import { AdminComponent } from './admin/admin.component';
import { VisitorsListComponent } from './visitors-list/visitors-list.component';
import { AuthGuardService } from './services/auth-guard.service';
import { DetailComponent } from './events/detail/detail.component';
import { EventsListComponent } from './events-list/events-list.component';


const routes: Routes = [
 /* { path: '/',  redirectTo: '/welcome', pathMatch: 'full' },*/
  { path: '' , component: WelcomeComponent },
  { path: 'search-pass' , component: SearchPassComponent },
  { path: 'contact' , component: ContactComponent },
  { path: 'events/:id' , component: DetailComponent },
  { path: 'admin' , component: AdminComponent },
  { path: 'logout' , component: AdminComponent },
  { path: 'visitors-list' , component: VisitorsListComponent, canActivate: [AuthGuardService] },
  { path: 'events-list' , component: EventsListComponent, canActivate: [AuthGuardService] },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
