import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CardComponent } from './card/card.component';
import { SearchPassComponent } from './search-pass/search-pass.component';
import { AdminComponent } from './admin/admin.component';
import { VisitorsListComponent } from './visitors-list/visitors-list.component';
import { FormsModule } from '@angular/forms';
import { NgxPrinterModule } from 'ngx-printer';
import { HttpClientModule } from '@angular/common/http';
import { VisitorsPassService } from './services/visitors-pass.service';
import { LoginService } from './services/login.service';
import { AuthGuardService } from './services/auth-guard.service';
import { ContactComponent } from './contact/contact.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CarouselComponent } from './carousel/carousel.component';
import { DetailComponent } from './events/detail/detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EventsListComponent } from './events-list/events-list.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    HeaderComponent,
    FooterComponent,
    CardComponent,
    SearchPassComponent,
    AdminComponent,
    VisitorsListComponent,
    ContactComponent,
    CarouselComponent,
    DetailComponent,
    EventsListComponent
  ],
  imports: [
    BrowserModule,    
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgxPrinterModule.forRoot({printOpenWindow: true}),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),   
    NgbModule
  ],
  providers: [VisitorsPassService, LoginService, AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
