<!-- carousel -->
<div class="carousel">
  <ol class="carousel-indicators">
    <li *ngFor="let slide of slides; let i = index" class="img-circle center-block" (click)="showCurrentSlide(i)"></li>
  </ol>
  <ng-container *ngFor="let slide of slides; let i = index">
    <img *ngIf="i === currentSlide" [src]="slide.src" class="slide" />
  </ng-container>

  <!-- controls -->
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>