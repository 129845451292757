<div class="card-main-container container">
   <div class="contact-container row">
        <div class="address-container col-lg-6">
            <div class="contact-heading"><h3>Contact Us</h3></div>
            <ul>
                <li><b>Fototech Events Pvt Ltd</b></li>
                <li>Plot No: 14, Green Hamlet Rd, Hanuman Nagar,</li>
                <li>Silpa Park, Kohtaguda, Telangana 500084</li>
                <li><b>Email : </b>phototraexpo@gmail.com</li>
                <li><b>Mobile : </b>8885556511, 9618980044</li>
                <li><b>Facebook : </b><a href="https://www.facebook.com/Phototradeexpo">https://www.facebook.com/Phototradeexpo</a></li>
            </ul> 
        </div>
        <div class="contact-image-container col-lg-6">
            <!-- <img src="../../assets/event-contactus.jpg" width="100%" /> -->
        </div>
    </div>
</div>    