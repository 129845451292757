import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  loginData = {
    username: '',
    password: '',
  }
  loading: boolean = true;
  errorMessage: string = '';
  constructor(private router: Router, private loginService: LoginService, ) { }

  ngOnInit(): void {
    if(this.router.url.indexOf('logout') >= 0)
    {
      this.logout();
    }else
    {
      if(sessionStorage.getItem("isLoggedIn") && sessionStorage.getItem("isLoggedIn") === "true") {
        this.router.navigate(['/visitors-list']);
      }
    }
    
  }

  clearMessages() {
    setTimeout(()=> {
      this.errorMessage = '';
    }, 3000)
  }

  login(loginForm) {
    if(loginForm.valid) {
      this.loading = true;
      this.loginService.login(this.loginData).subscribe(data => {
        this.loading = false;
        if(data === "success") {
          this.router.navigate(['/visitors-list']);
          sessionStorage.setItem("isLoggedIn", "true");
        } else {
          this.errorMessage = "Incorrect Username or Password";
        }
        this.clearMessages();
      }, error => {
        this.loading = false;
        this.errorMessage = "Incorrect Username or Password";
        this.clearMessages();
      });
    }
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate['/login'];
  }

}
