<div class="card-main-container container">
    <div class="row">
        <div class="col-md-3">
            <ul class="nav nav-pills nav-stacked">
                <li class="active"><a href="javascript:void(0)"><i class="fa fa-users-cog"></i>Visitors List</a></li>
                <li><a routerLink="/events-list"><i class="fa fa-calendar-alt"></i>Events List</a></li>
                <li><a  href="javascript:void(0)" (click)="triggerModal(modalData)"><i class="fa fa-calendar-alt"></i>Add Event</a></li>
                <li><a routerLink="/logout">Logout</a></li>
            </ul>
        </div>
        <div class="col-md-9 well">
            <div>
                <h3>Visitors List</h3>
            </div>
            <div class="filter-container">
                <input type="text" class="filter-input" placeholder="Type to filter the name column..."
                    (keyup)="updateFilter($event)" />
                <span class="pull-right"><button type="button" class="btn btn-primary pull-right" (click)="exportExcel()">Export</button></span>
            </div>
            <div class="Visitors-list-container">
				<div class="text-center" *ngIf="showLoader">
					<img src="../../assets/loading.gif" width="200" height="200" />
				</div>
                <ngx-datatable *ngIf="!showLoader" #myFilterTable class="bootstrap" [rows]="visitorsData" [columns]="columns"
                    [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [limit]="20">
                </ngx-datatable>
            </div>
        </div>
    </div>

</div>
<ng-template #modalData let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Event</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form">
            <form #visitorPass="ngForm" name="visitorPass" (ngSubmit)="createEvent(visitorPass)">
                <div class="row">
                    <div class="form-group col-lg-4">
                        <label>Event Name <span class="error">*</span></label>
                        <input #eventName="ngModel" pattern="^[a-zA-z][0-9a-zA-z ]+$" type="text"
                            [(ngModel)]="visitorData.eventName" name="eventName" class="form-control" id="eventName"
                            placeholder="Event Name" required>
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && eventName.invalid">
                            <p *ngIf="eventName.errors.required">Event Name is required.</p>
                            <p *ngIf="eventName.errors.pattern">Please enter valid Event name.</p>
                        </div>
                    </div>
                    <div class="form-group col-lg-4">
                        <label>Event Date</label>
                        <input #event_date="ngModel" bsDatepicker type="text" [(ngModel)]="visitorData.event_date"
                            name="event_date" class="form-control" id="event_date" placeholder="Event Date">
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && event_date.invalid">
                            <p *ngIf="event_date.errors.pattern">Please enter Date.</p>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 mt-3 mt-lg-0">
                        <label>Description <span class="error">*</span></label>
                        <textarea #description="ngModel" [(ngModel)]="visitorData.description" class="form-control"
                            name="description" id="description" placeholder="Event Description" required> </textarea>
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && description.invalid">
                            <p *ngIf="description.errors.required">Description is required.</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="form-group col-lg-4">
                        <label>Photo <span class="error">*</span></label>
                        <input (change)="uploadImage($event)" #photo="ngModel" type="file" [(ngModel)]="visitorImage"
                            class="form-control" name="photo" id="photo" placeholder="Upload Photo" required>
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && photo.invalid">
                            <p *ngIf="photo.errors.required">Photo is required.</p>
                        </div>
                        <div class="text-danger mt-1" *ngIf="isPhotoInvalid">
                            <p>{{photoErrorMsg}}</p>
                        </div>
                    </div>
                    <div class="form-group col-lg-4">
                        <label>Contact Address <span class="error">*</span></label>
                        <textarea #contact_address="ngModel" name="contact_address"
                            [(ngModel)]="visitorData.contact_address" class="form-control" id="contact_address"
                            placeholder="Address for communication" required>
                  </textarea>
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && contact_address.invalid">
                            <p *ngIf="contact_address.errors.required">Communication Address is required.</p>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 mt-3 mt-lg-0">
                        <label>Contact Phone <span class="error">*</span></label>
                        <input maxlength="30" minlength="10" #contact_phone="ngModel" pattern="[0-9,]+$" type="text"
                            name="contact_phone" [(ngModel)]="visitorData.contact_phone" class="form-control" id="phone"
                            placeholder="Phone" required>
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && contact_phone.invalid">
                            <p *ngIf="contact_phone.errors.required">Phone Number is required.</p>
                            <p *ngIf="contact_phone.errors.pattern || contact_phone.errors.minlength || contact_phone.errors.maxlength">
                                Please enter valid Phone Number.</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="form-group col-lg-4">
                        <label>Contact Email Address <span class="error">*</span></label>
                        <input #contact_email="ngModel" type="text" [(ngModel)]="visitorData.contact_email"
                            class="form-control" name="email" id="contact_email" placeholder="Contact Email" required
                            email>
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && contact_email.invalid">
                            <p *ngIf="contact_email.errors.required">Email is required.</p>
                            <p *ngIf="contact_email.errors.email">Please enter valid email address.</p>
                        </div>
                    </div>
                    <div class="form-group col-lg-8">
                        <label>Event Location <span class="error">*</span></label>
                        <input #event_location="ngModel" type="text" [(ngModel)]="visitorData.event_location"
                            class="form-control" name="event_location" placeholder="Event Location" required>
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && event_location.invalid">
                            <p *ngIf="event_location.errors.required">Location is required.</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="form-group col-lg-4">
                        <label>Event Address <span class="error">*</span></label>
                        <textarea #event_address="ngModel" name="event_address" [(ngModel)]="visitorData.event_address"
                            class="form-control" id="event_address" placeholder="Event Address" required>
                </textarea>
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && event_address.invalid">
                            <p *ngIf="event_address.errors.required">Event Address is required.</p>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 mt-3 mt-lg-0">
                        <label>Event Phone <span class="error">*</span></label>
                        <input maxlength="30" minlength="10" #event_phone="ngModel" pattern="[0-9,]+$" type="text"
                            name="event_phone" [(ngModel)]="visitorData.event_phone" class="form-control"
                            id="event_phone" placeholder="Phone" required>
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && event_phone.invalid">
                            <p *ngIf="event_phone.errors.required">Phone Number is required.</p>
                            <p
                                *ngIf="event_phone.errors.pattern || event_phone.errors.minlength || event_phone.errors.maxlength">
                                Please enter valid Phone Number.</p>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="form-group col-lg-4">
                        <label>Event Email Address <span class="error">*</span></label>
                        <input type="email" #event_email="ngModel" name="event_email"
                            [(ngModel)]="visitorData.event_email" class="form-control" id="event_email"
                            placeholder="Email Address for Event" required />
                        <div class="text-danger mt-1" *ngIf="visitorPass.submitted && event_email.invalid">
                            <p *ngIf="event_email.errors.required">Email Address is required.</p>
                        </div>
                    </div>
                </div>
                <div class="my-3">
                    <div class="loading" *ngIf="loading">Loading</div>
                    <div class="error-message" *ngIf="errorMessage">{{errorMessage}}</div>
                    <div class="success-message" *ngIf="successMessage">Your details submitted successfully. Thank you!
                    </div>
                </div>
                <div class="text-right">
                    <button type="reset" (click)="modal.dismiss('Cross click')" title="Cancel"
                        class="btn btn-secondary">Cancel</button>
                    <button type="submit" title="Create Event" class="btn btn-primary">Create Event</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>