import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VisitorsPassService } from 'src/app/services/visitors-pass.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  closeModal: string;  

  designations: string[] = ['Photographer', 'Videographer', 'Graphic Designer', 'Photo Trader', 'Color Lab', 'Media', 'Student', 'Hobbyist', 'Others'];
  isPhotoInvalid: boolean = false;
  loading:boolean = false;
  errorMessage: string = '';
  successMessage: boolean = false;
  photoErrorMsg: string = '';
  visitorImage: any = '';
  cardData: any;
  visitorData: any = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    designation: '',
    businessName: '',
    gender: '',
    address: '',
    city: '',
    state: '',
    photo: ''
  }
  showCard: boolean = false;
  eventDetails: any = [];

  constructor(private modalService: NgbModal, private route: ActivatedRoute, private visitorsPassService: VisitorsPassService) { }

  ngOnInit(): void {
    let event_id = this.route.snapshot.paramMap.get('id');
    event_id= event_id.replace('foto', '').replace('pass','');
    this.visitorsPassService.getEventDetails(event_id).subscribe(response => {
      response[0].event_date = new Date(response[0].event_date);
      this.eventDetails = response;      
    });
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
  }


  uploadImage(event) {
    if(event.target.value) {
      this.visitorData.photo = '';
      const file = event.target.files[0];
      const fileSize = Math.round((file.size/1024)/1000);
      if(fileSize > 1) {
        this.isPhotoInvalid = true;
        this.photoErrorMsg = "Image size must be below 1mb";
      } else if(file.type === "image/png" || file.type === "image/jpg"  || file.type === "image/jpeg") {
        this.isPhotoInvalid = false;
        this.changeFile(file).then((base64: string): any => {
          this.visitorData.photo =  base64;
        });
      } else {        
        this.isPhotoInvalid = true;
        this.photoErrorMsg = "Only JPEG's and Png file types are allowed";
      }
    }
  }

  clearMessages() {
    setTimeout(()=> {
      this.errorMessage = '';
    }, 3000)
  }

  register(visitorForm) {
    if(visitorForm.valid && !this.isPhotoInvalid) {
      this.loading = true;
      this.visitorData.event_id = this.route.snapshot.paramMap.get('id').replace('foto', '').replace('pass','');
      this.visitorsPassService.createVisitor(this.visitorData).subscribe(data => {
        this.loading = false;
        if(data && data.phone && data.email) {
          this.cardData = data;
          this.successMessage = true;
          setTimeout(() => {
            this.modalService.dismissAll();
            this.showCard = true;
          }, 2000);
        } else {
          if(data === "error") {
            this.errorMessage = 'Error Occurred while registration, please try again!';
          } else {
            this.errorMessage = data;
          }
          this.clearMessages();
        }
      }, error => {
        this.loading = false;
        this.errorMessage = 'Error Occurred while registration, please try again!';
        this.clearMessages();
      });
    }
  }


  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
