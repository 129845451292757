import { Component, OnInit } from '@angular/core';
import { VisitorsPassService } from '../services/visitors-pass.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-pass',
  templateUrl: './search-pass.component.html',
  styleUrls: ['./search-pass.component.scss']
})
export class SearchPassComponent implements OnInit {
  searchData = {
    email: '',
    phone: ''
  }
  visitorData = {};
  errorMessage: string = '';
  loading:boolean = false;
  successMessage: boolean = false;
  showCard: boolean = false;
  showLoader: boolean = true;
  events: any = [];
  constructor(private router: Router, private visitorsPassService: VisitorsPassService) { }

  ngOnInit(): void {
	this.showLoader = true;
	this.visitorsPassService.getEvents(true).subscribe(events => {
      this.events = events;
	  this.showLoader = false;
    });
  }

  clearMessages() {
    setTimeout(()=> {
      this.errorMessage = '';
    }, 3000)
  }

  search(visitorForm) {
    if(visitorForm.valid) {
      this.loading = true;
      this.visitorsPassService.getVisitor(this.searchData).subscribe(data => {
        this.loading = false;
        if(data && data.email && data.phone) {
          this.visitorData = data;
          this.showCard = true;          
        } else {
          this.errorMessage = "No Visitor found with the provided Event Name and mobile number";
          this.clearMessages();
        }
      }, error => {
        this.loading = false;
        this.errorMessage = "Error Occurred while finding visitor, please try again!";
        this.clearMessages();
      });
    } 
  }

  goToHome() {
    this.router.navigate(['/']);
  }

}
