<div *ngIf="!showCard" class="main-container container search-pass-container">
    <div><h3>Search your Visitor Pass</h3></div>
    <div class="form search-form-container">
        <form #visitorPass="ngForm" name="visitorPass" (ngSubmit)="search(visitorPass)">
            <div class="row">
                <div class="col-12">
                    <div *ngIf="errorMessage" class="error-message">{{errorMessage}}</div>
                </div>
            </div>
			<div class="text-center" *ngIf="showLoader">
				<img src="../../assets/loading.gif" width="200" height="200" />
			</div>
            <div class="row" *ngIf="!showLoader">
                <div class="form-group col-lg-12">
                    <label>Event Name <span class="error">*</span></label>
                    
					<select #email="ngModel" [(ngModel)]="searchData.email" class="form-control" name="email" required>
						<option value=''>--Select Event--</option>
						<option *ngFor="let eventdetail of events" [value]="eventdetail.eventname">{{eventdetail.eventname}}</option>
					</select>
                    <div class="text-danger mt-1" *ngIf="visitorPass.submitted && email.invalid">
                        <p *ngIf="email.errors.required">Event Name is required.</p>
                        <!-- <p *ngIf="email.errors.email">Please enter valid email address.</p> -->
                    </div> 
                </div>
            </div>
            <div class="row" *ngIf="!showLoader">    
                <div class="form-group col-lg-12 mt-3 mt-lg-0">
                    <label>Phone Number <span class="error">*</span></label>
                    <input maxlength="10" minlength="10" #phone="ngModel" pattern="[0-9]+$" type="text" name="phone" [(ngModel)]="searchData.phone" class="form-control" id="phone" placeholder="Phone" required>
                    <div class="text-danger mt-1" *ngIf="visitorPass.submitted && phone.invalid">
                        <p *ngIf="phone.errors.required">Phone Number is required.</p>
                        <p *ngIf="phone.errors.pattern || phone.errors.minlength || phone.errors.maxlength">Please enter valid Phone Number.</p>
                    </div>
                </div>
            </div>
            <div class="my-3">
            <div class="loading" *ngIf="loading">Loading</div>
            </div>
            <div class="text-center">
                <!--<button type="button" title="Back" (click)="goToHome()" class="btn btn-secondary">Back</button>-->
                <button type="submit" title="Search" class="btn btn-primary">Search</button>
            </div>
        </form>
    </div>
</div>
<app-card *ngIf="showCard" [visitorData]="visitorData"></app-card>