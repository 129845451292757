<div class="main-container container">
    <div><h3>Login</h3></div>
    <div class="form login-form-container">
        <form #loginForm="ngForm" name="loginForm" (ngSubmit)="login(loginForm)">
            <div class="row">
                <div class="col-12">
                    <div *ngIf="errorMessage" class="error-message">{{errorMessage}}</div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-12">
                    <label>Username <span class="error">*</span></label>
                    <input #username="ngModel" type="text" [(ngModel)]="loginData.username" class="form-control" name="username" id="username" placeholder="Username" required>
                    <div class="text-danger mt-1" *ngIf="loginForm.submitted && username.invalid">
                        <p *ngIf="username.errors.required">Username is required</p>
                    </div>
                </div>
            </div>
            <div class="row">    
                <div class="form-group col-lg-12 mt-3 mt-lg-0">
                    <label>Password <span class="error">*</span></label>
                    <input minlength="5" #password="ngModel" type="password" name="password" [(ngModel)]="loginData.password" class="form-control" id="password" placeholder="Password" required>
                    <div class="text-danger mt-1" *ngIf="loginForm.submitted && password.invalid">
                        <p *ngIf="password.errors.required">Password is required</p>
                        <p *ngIf="password.errors.maxlength || password.errors.minlength">Invalid Password</p>
                    </div>
                </div>
            </div>
            <div class="text-center mt-3">
                <button type="submit" title="Login" class="btn btn-primary">Login</button>
            </div>
        </form>
    </div>
</div>