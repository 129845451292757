import { Component, OnInit, ViewChild } from '@angular/core';
import { VisitorsPassService } from '../services/visitors-pass.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-visitors-list',
  templateUrl: './visitors-list.component.html',
  styleUrls: ['./visitors-list.component.scss']
})
export class VisitorsListComponent implements OnInit {
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent;
  tempVisitorsData: any[] = [];
  visitorsData: any[] = [];
  closeModal: string;
  errorMessage: string = '';
  successMessage: boolean = false;
  isPhotoInvalid: boolean = false;
  photoErrorMsg: string = '';
  visitorImage: any = '';
  loading: boolean = false;
  columns = [
    { prop: 'firstName', name: 'First Name'},
    { prop: 'lastName', name: 'Last Name'},
    { prop: 'email', name: 'Email'},
    { prop: 'phone', name: 'Phone'},
    { prop: 'designation', name: 'Title'},
    { prop: 'businessName', name: 'Business Name'},
    { prop: 'gender', name: 'Gender'},
    { prop: 'city', name: 'City'},
    { prop: 'state', name: 'State'},
    { prop: 'registrationDate', name: 'Registered On'}
  ]
  visitorData: any = {
    eventName: '',
    event_date: '',
    description: '',
    photo: '',
    contact_address: '',
    contact_phone: '',
    contact_email: '',
    event_location: '',
    event_address: '',
    event_phone: '',
    event_email: ''
  }
  showLoader: boolean = true;
  constructor(private visitorsPassService: VisitorsPassService, private modalService: NgbModal) { }

  ngOnInit(): void {
	this.showLoader = true;
    this.visitorsPassService.getVisitors().subscribe(data => {
      // cache our list
	  this.showLoader = false;
      this.tempVisitorsData = [...data];
      this.visitorsData = data;
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempVisitorsData.filter(function (d) {
      return d.firstName.toLowerCase().indexOf(val) !== -1 || 
        d.lastName.toLowerCase().indexOf(val) !== -1 || 
        d.email.toLowerCase().indexOf(val) !== -1 || 
        d.designation.toLowerCase().indexOf(val) !== -1 || 
        d.phone.indexOf(val) !== -1 || 
        d.businessName.toLowerCase().indexOf(val) !== -1 || 
        d.gender.toLowerCase().indexOf(val) !== -1 || 
        d.city.toLowerCase().indexOf(val) !== -1 || 
        d.state.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.visitorsData = temp;
    // Whenever the filter changes, always go back to the first page
    this.myFilterTable.offset = 0;
  }

  exportExcel() {
 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Registered Users');
    worksheet.columns = [
      { header: 'First Name', key: 'firstname', width: 42 },
      { header: 'Email Address', key: 'email', width: 50 },
      { header: 'City', key: 'city', width: 20 },
	  { header: 'Phone Number', key: 'phone', width: 20 },
      { header: 'Designation', key: 'designation', width: 20 },
      { header: 'Business Name', key: 'businessName', width: 20 },
      { header: 'Address', key: 'address', width: 20 },
    ];
    this.visitorsData.forEach(record => {
      worksheet.addRow({firstname: record.firstName, email: record.email, city: record.city, phone: record.phone, designation: record.designation, businessName: record.businessName, address: record.address})
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Registered Users.xlsx');
    })
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  triggerModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      // this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  createEvent(visitorForm) {
    if (visitorForm.valid && !this.isPhotoInvalid) {
      this.loading = true;
      this.visitorsPassService.createEvent(this.visitorData).subscribe(data => {
        this.loading = false;
        if (data && data.contact_email) {
          this.successMessage = true;
        } else {
          if (data === "error") {
            this.errorMessage = 'Error Occurred while creating the event, please try again!';
          } else {
            this.errorMessage = data;
          }
          this.clearMessages();
        }
      }, error => {
        this.loading = false;
        this.errorMessage = 'Error Occurred while creating the event, please try again!';
        this.clearMessages();
      });
    }
  }

  clearMessages() {
    setTimeout(() => {
      this.errorMessage = '';
    }, 3000)
  }

  uploadImage(event) {
    if (event.target.value) {
      this.visitorData.photo = '';
      const file = event.target.files[0];
      const fileSize = Math.round((file.size / 1024) / 1000);
      if (fileSize > 1) {
        this.isPhotoInvalid = true;
        this.photoErrorMsg = "Image size must be below 1mb";
      } else if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
        this.isPhotoInvalid = false;
        this.changeFile(file).then((base64: string): any => {
          this.visitorData.photo = base64;
        });
      } else {
        this.isPhotoInvalid = true;
        this.photoErrorMsg = "Only JPEG's and Png file types are allowed";
      }
    }
  }


}

