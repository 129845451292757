import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { encode } from 'node:querystring';
import { VisitorsPassService } from '../services/visitors-pass.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  showCard: boolean = false;
  designations: string[] = ['Photographer', 'Videographer', 'Graphic Designer', 'Photo Trader', 'Color Lab', 'Media', 'Student', 'Hobbyist', 'Others'];
  isPhotoInvalid: boolean = false;
  loading:boolean = false;
  errorMessage: string = '';
  successMessage: boolean = false;
  photoErrorMsg: string = '';
  visitorImage: any = '';
  cardData: any;
  visitorData: any = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    designation: '',
    businessName: '',
    gender: '',
    address: '',
    city: '',
    state: '',
    photo: ''
  }
  events: any = [];
  showLoader: boolean = true;

  slides: any = [
    {src: '../../assets/slider.jpeg'},
    {src: 'https://images.unsplash.com/photo-1616658068275-496ec2a5d36d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80'},
    {src: 'https://images.unsplash.com/photo-1610065065737-64ecb7c5f211?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1534&q=80'}
  ];
  constructor(private visitorsPassService: VisitorsPassService, private route: Router) { }

  ngOnInit(): void {
	this.showLoader = true;
    this.visitorsPassService.getEvents(true).subscribe(events => {
      this.events = events;
	  this.showLoader = false;
    });    
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
  }


  uploadImage(event) {
    if(event.target.value) {
      this.visitorData.photo = '';
      const file = event.target.files[0];
      const fileSize = Math.round((file.size/1024)/1000);
      if(fileSize > 1) {
        this.isPhotoInvalid = true;
        this.photoErrorMsg = "Image size must be below 1mb";
      } else if(file.type === "image/png" || file.type === "image/jpg"  || file.type === "image/jpeg") {
        this.isPhotoInvalid = false;
        this.changeFile(file).then((base64: string): any => {
          this.visitorData.photo =  base64;
        });
      } else {        
        this.isPhotoInvalid = true;
        this.photoErrorMsg = "Only JPEG's and Png file types are allowed";
      }
    }
  }

  clearMessages() {
    setTimeout(()=> {
      this.errorMessage = '';
    }, 3000)
  }

  register(visitorForm) {
    if(visitorForm.valid && !this.isPhotoInvalid) {
      this.loading = true;
      this.visitorsPassService.createVisitor(this.visitorData).subscribe(data => {
        this.loading = false;
        if(data && data.phone && data.email) {
          this.cardData = data;
          this.successMessage = true;
          setTimeout(() => {
          this.showCard = true;
          }, 2000);
        } else {
          if(data === "error") {
            this.errorMessage = 'Error Occurred while registration, please try again!';
          } else {
            this.errorMessage = data;
          }
          this.clearMessages();
        }
      }, error => {
        this.loading = false;
        this.errorMessage = 'Error Occurred while registration, please try again!';
        this.clearMessages();
      });
    }
  }

  listDetail(eventid) {
    const encoded_str = 'foto'+eventid+'pass';
    this.route.navigate(['events/'+encoded_str]);
  }

  counter(i: number) {
    i = Math.ceil(i/4);
    return new Array(i);
  }
}
