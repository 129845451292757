<div *ngIf="!showCard">
    <!-- <img src="../../assets/slider.jpeg" width="100%"/> -->
    <div class="container-block">
        <app-carousel [slides]="slides"></app-carousel>
    </div>
</div>
<div *ngIf="!showCard" class="welcome-container container">
    <h1 class="upcomingevents">Upcoming Events</h1>
    <div class="row row-margin-bottom mt-5" *ngIf="events.length > 0">
        <div class="row" *ngFor="let in of counter(events.length);let i = index;">
            <div class="col-md-3 col-lg-3 mt-4" *ngFor="let eventdetail of events.slice(i*4, (i*4)+4)">
                <div class="card card-inverse card-info" (click)="listDetail(eventdetail.eventid)">
                    <img class="card-img-top" width="200" height="150" src="{{eventdetail.photo}}">
                    <div class="card-block">
                        <h4 class="card-title">{{eventdetail.eventname}}</h4>
                        <div class="card-text">
                            <p>{{eventdetail.event_location}}<br />{{eventdetail.event_date | date: 'fullDate'}}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row row-margin-bottom mt-5" *ngIf="events.length == 0 && !showLoader">
        <p style="color: #000">No New Events Available</p>
    </div>
	<div class="text-center" *ngIf="showLoader">
		<img src="../../assets/loading.gif" width="200" height="200" />
	</div>
    <app-card *ngIf="showCard" [visitorData]="cardData"></app-card>