<div class="clearfix"></div>
<div class="welcome-container container" *ngIf="eventDetails.length > 0 && !showCard">
    <div class="row row-margin-bottom mt-5">
        <div class="row">
            <p>{{eventDetails[0].event_date | date: 'fullDate'}}</p>
            <div class="row col-md-12">
                <div class="col-md-5">
                    <h1>{{eventDetails[0].eventname}}</h1>
                </div>
                <div class="col-md-7">
                    <button class="btn btn-primary btn-lg" (click)="triggerModal(modalData)">Register</button>
                </div>                
            </div>
            <!-- <h1>{{eventDetails[0].eventname}} <span style="float: right;"><button class="btn btn-primary btn-lg" (click)="triggerModal(modalData)">Register</button></span></h1> -->
            <div class="row mb-2">
                <div class="col-md-12 row mt-5 mb-5">
                    <div class="col-md-5">
                        <img width="400" height="200" src="{{eventDetails[0].photo}}">
                    </div>
                    <div class="col-md-7">
                        <address>
                            <b>Location:</b>{{eventDetails[0].event_location}}<br/>
                            <!-- <b>Fototech Events Pvt Ltd</b><br/> -->
                            <!-- Plot No: 14, Green Hamlet Rd, Hanuman Nagar,<br/>
                            Silpa Park, Kothaguda, Telangana 500084<br/> -->
                            {{eventDetails[0].event_address}}<br/>
                            <b>Email : </b><a href="emailto:{{eventDetails[0].event_email}}">{{eventDetails[0].event_email}}</a><br/>
                            <b>Mobile : </b>{{eventDetails[0].event_phone}}<br/>
                                
                        </address>
                        <address>
                            <b>Contact Us:</b><br/>
                            <!-- <b>Fototech Events Pvt Ltd</b><br/> -->
                            <!-- Plot No: 14, Green Hamlet Rd, Hanuman Nagar,<br/>
                            Silpa Park, Kothaguda, Telangana 500084<br/> -->
                            {{eventDetails[0].contact_address}}<br/>
                            <b>Email : </b><a href="emailto:{{eventDetails[0].contact_email}}">{{eventDetails[0].contact_email}}</a><br/>
                            <b>Mobile : </b>{{eventDetails[0].contact_phone}}<br/>
                                
                        </address>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-md-12 row">
                    <h4><u>Description</u></h4>
                    <p>{{eventDetails[0].description}}</p>
                </div>
            </div>

            
        </div>

    </div>

</div>
<div class="clearfix"><br/></div>
<app-card *ngIf="showCard" [visitorData]="cardData"></app-card>

<ng-template #modalData let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Registration for the Event - {{eventDetails[0].eventname}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="form">
    <form #visitorPass="ngForm" name="visitorPass" (ngSubmit)="register(visitorPass)">
        <div class="row">
            <div class="form-group col-lg-4">
                <label>First Name <span class="error">*</span></label>
                <input #fName="ngModel" pattern="^[a-zA-z][a-zA-z ]+$" type="text" [(ngModel)]="visitorData.firstName" name="firstName" class="form-control" id="firstName" placeholder="First Name" required>
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && fName.invalid">
                    <p *ngIf="fName.errors.required">First Name is required.</p>
                    <p *ngIf="fName.errors.pattern">Please enter valid first name.</p>
                </div>
            </div>
            <div class="form-group col-lg-4">
                <label>Last Name</label>
                <input #lName="ngModel" pattern="^[a-zA-z][a-zA-z ]+$" type="text" [(ngModel)]="visitorData.lastName" name="lastName" class="form-control" id="lastName" placeholder="Last Name">
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && lName.invalid">
                    <p *ngIf="lName.errors.pattern">Please enter valid last name.</p>
                </div>
            </div>
            <div class="form-group col-lg-4 mt-3 mt-lg-0">
                <label>Email <span class="error">*</span></label>
                <input #email="ngModel" type="text" [(ngModel)]="visitorData.email" class="form-control" name="email" id="email" placeholder="Your Email" required email>
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && email.invalid">
                    <p *ngIf="email.errors.required">Email is required.</p>
                    <p *ngIf="email.errors.email">Please enter valid email address.</p>
                </div>
            </div>
        </div>
        <div class="row mt-3">    
            <div class="form-group col-lg-4">
                <label>Phone Number <span class="error">*</span></label>
                <input maxlength="10" minlength="10" #phone="ngModel" pattern="[0-9]+$" type="text" name="phone" [(ngModel)]="visitorData.phone" class="form-control" id="phone" placeholder="Phone" required>
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && phone.invalid">
                    <p *ngIf="phone.errors.required">Phone Number is required.</p>
                    <p *ngIf="phone.errors.pattern || phone.errors.minlength || phone.errors.maxlength">Please enter valid Phone Number.</p>
                </div>
            </div>
            <div class="form-group col-lg-4">
                <label>Title/Designation <span class="error">*</span></label>
                <select #designation="ngModel" name="designation" [(ngModel)]="visitorData.designation" class="form-control" id="designation" placeholder="Your Designation" required>
                    <option value="">Select</option>
                    <option value="{{designation}}" *ngFor="let designation of designations">{{designation}}</option>
                </select>
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && designation.invalid">
                    <p *ngIf="designation.errors.required">Title/Designation is required.</p>
                </div>
            </div>
            <div class="form-group col-lg-4 mt-3 mt-lg-0">
                <label>Business Name <span class="error">*</span></label>
                <input #businessName="ngModel" type="text" [(ngModel)]="visitorData.businessName" class="form-control" name="businessName" id="businessName" placeholder="Your Business Name" required>
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && businessName.invalid">
                    <p *ngIf="businessName.errors.required">Business Name is required.</p>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="form-group col-lg-4">
                <label>Gender <span class="error">*</span></label>
                <select #gender="ngModel" name="gender" [(ngModel)]="visitorData.gender" class="form-control" id="gender" placeholder="Gender" required>
                    <option value="">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && gender.invalid">
                    <p *ngIf="gender.errors.required">Gender is required.</p>
                </div>
            </div>
            <div class="form-group col-lg-8">
                <label>Address </label>
                <input #address="ngModel" type="text" [(ngModel)]="visitorData.address" class="form-control" name="address" placeholder="Address">
                
            </div>
        </div>
        <div class="row mt-3">   
            <div class="form-group col-lg-4">
                <label>City/Town <span class="error">*</span></label>
                <input #city="ngModel" type="text" [(ngModel)]="visitorData.city" class="form-control" name="city" id="city" placeholder="City" required>
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && city.invalid">
                    <p *ngIf="city.errors.required">City/Town is required.</p>
                </div>
            </div>
            <div class="form-group col-lg-4 mt-3 mt-lg-0">
                <label>State <span class="error">*</span></label>
                <input #state="ngModel" type="text" [(ngModel)]="visitorData.state" class="form-control" name="state" id="state" placeholder="State" required>
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && state.invalid">
                    <p *ngIf="state.errors.required">State is required.</p>
                </div>
            </div>
            <div class="form-group col-lg-4 mt-3 mt-lg-0">
                <label>Photo <span class="error">*</span></label>
                <input (change)="uploadImage($event)" #photo="ngModel" type="file" [(ngModel)]="visitorImage" class="form-control" name="photo" id="photo" placeholder="Upload Photo" required>
                <div class="text-danger mt-1" *ngIf="visitorPass.submitted && photo.invalid">
                    <p *ngIf="photo.errors.required">Photo is required.</p>
                </div>
                <div class="text-danger mt-1" *ngIf="isPhotoInvalid">
                    <p>{{photoErrorMsg}}</p>
                </div>
            </div>
        </div>
        <div class="my-3">
        <div class="loading" *ngIf="loading">Loading</div>
        <div class="error-message" *ngIf="errorMessage">{{errorMessage}}</div>
        <div class="success-message" *ngIf="successMessage">Your details submitted successfully. Thank you!</div>
        </div>
        <div class="text-right">
            <button type="reset" (click)="modal.close()" id="cancelModal" title="Cancel" class="btn btn-secondary">Cancel</button>
            <button type="submit" title="Register" class="btn btn-primary">Register</button>
        </div>
    </form>
    </div>    
  </div>  
</ng-template>